import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'UpdateLocation',
  components: {
    DatePicker,
    commonHelper,
    appStrings
  },
  watch: {
    currentPage() {
      this.getTransferAssetsList();
    },
    perPage() {
      this.currentPage = 1;
      this.getTransferAssetsList();
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      showHideBatchDetailsModal: false,
      showValueSetModal: false,
      showExcelUploadModal: false,
      selectAllCheckBox: false,
      rowSelected: false,
      loader: false,
      legalEntity: {
        text: null,
        value: null
      },
      bookTypeCode: {
        value: null,
        text: null
      },
      fromLocation: {
        text: null,
        value: null
      },
      toLocation: {
        text: null,
        value: null
      },
      status: {
        text: null,
        value: null
      },
      batchNo: '',
      creationDate: '',
      transferAssetData: [],
      transferAssetFields: [
        {
          key: 'select'
        },
        {
          key: 'tmpHdrId',
          label: 'Hdr Id'
        },
        {
          key: 'batchId',
          label: 'Excel Batch Id'
        },
        {
          key: 'from_loc_id'
        },
        {
          key: 'from_loc_id_by_name',
          label: 'From Loc Id By Name'
        },
        {
          key: 'to_loc_id'
        },
        {
          key: 'assetNo'
        },
        {
          key: 'asset_unit'
        },
        {
          key: 'book_type_code'
        },
        {
          key: 'status'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'createdBy'
        },
        {
          key: 'createdByName',
          label: 'Created By Name'
        },
        {
          key: 'last_updated_date'
        },
        {
          key: 'last_updated_by'
        },
        {
          key: 'inventory_item_code'
        }
      ]
    };
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'print') {
          this.showExcelUploadModal = true;
        }

        if (actionName === 'save') {
          this.processAsset();
        }
      }
    });
  },
  methods: {
    getTransferAssetsList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.legalEntity.value,
        bookTypeCode: this.bookTypeCode.value,
        fromLocId: this.fromLocation.value,
        toLocId: this.toLocation.value,
        status: this.status.value,
        batchId: this.batchNo,
        creationDate: commonHelper.formattedDate(this.creationDate)
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getTransferAssetTmpDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.transferAssetData = result.map(data => {
              data.select = false;
              return data;
            });
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    openValueSetModal(vsetCode) {
      this.chooseParentVsetId(vsetCode);
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.legalEntity = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.bookTypeCode = {
            value: null,
            text: null
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.bookTypeCode = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.TO_FA_LOCATION:
          if (this.locType === 'from_loc') {
            this.fromLocation = {
              text: item.value_code,
              value: item.value_set_dtl_id
            };
            this.locType = null;
          } else if (this.locType === 'to_loc') {
            this.toLocation = {
              text: item.value_code,
              value: item.value_set_dtl_id
            };
            this.locType = null;
          }
          break;
        case appStrings.VALUESETTYPE.EXCEL_STATUS:
          this.status = {
            value: item.value_meaning,
            text: item.value_code
          };
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    mainSearch() {
      this.getTransferAssetsList();
    },
    clear() {
      this.legalEntity = {
        text: null,
        value: null
      };
      this.bookTypeCode = {
        text: null,
        value: null
      };
      this.fromLocation = {
        text: null,
        value: null
      };
      this.toLocation = {
        text: null,
        value: null
      };
      this.status = {
        text: null,
        value: null
      };
      this.batchNo = '';
      this.creationDate = [];
      this.transferAssetData = [];
      this.totalRows = null;
    },
    chooseValueset(name, locType) {
      this.locType = locType === 'from' ? 'from_loc' : 'to_loc';
      switch (name) {
        case 'legal_entity':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          break;
        case 'book_type':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_BOOK_TYPE);
          break;
        case 'from_fa_location':
        case 'to_fa_location':
          this.openValueSetModal(appStrings.VALUESETTYPE.TO_FA_LOCATION);
          break;
        case 'status':
          this.openValueSetModal(appStrings.VALUESETTYPE.EXCEL_STATUS);
          break;
      }
    },
    chooseParentVsetId(name) {
      switch (name) {
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.parent_value_set_id = this.legalEntity.value;
          break;
        default:
          this.parent_value_set_id = null;
      }
    },
    openCloseExcelUploadModal(flag) {
      this.showExcelUploadModal = flag;
    },
    updateExcelBatchId(item) {
      this.batchNo = item;
    },

    processAsset() {
      const unProcessedAssets = this.transferAssetData.filter(
        data => data.select
      );
      if (unProcessedAssets && unProcessedAssets.length > 0) {
        let allowedAssets = [];
        if (this.checkAssetsLength(50, unProcessedAssets)) {
          allowedAssets = commonHelper.removeExcessRecords(
            50,
            unProcessedAssets
          );
        } else {
          allowedAssets = unProcessedAssets;
        }
        const details = allowedAssets.map(data => {
          return {
            inventory_item_code: data.inventory_item_code,
            status: data.status,
            le_id: data.le_id,
            createdBy: data.createdBy,
            book_type_code: data.book_type_code,
            batchId: data.batchId,
            asset_unit: data.asset_unit,
            from_loc_id: data.from_loc_id,
            tmpHdrId: data.tmpHdrId,
            to_loc_id: data.to_loc_id,
            assetNo: data.assetNo
          };
        });
        const payload = {
          asset_details: details
        };
        this.loader = true;
        this.$store
          .dispatch('assets/postUpdateLocationProcess', payload)
          .then(response => {
            this.loader = false;
            this.dismissCountDown = 5;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },

    checkAssetsLength(permittedLength, arr) {
      if (arr.length > permittedLength) {
        this.dismissCountDown = 5;
        this.isSuccess = false;
        this.responseMsg = 'Only 50 records processed!';
        return true;
      }
    },

    rowSelected() {},
    validateCheckBox(row) {
      if (row.request_num) {
        if (!/e/i.test(row.book_type_code)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'legal_entity') {
        this.legalEntity = {
          value: null,
          text: null
        };
        this.bookTypeCode = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'book_type') {
        this.bookTypeCode = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'from_fa_location') {
        this.fromLocation = {
          text: null,
          value: null
        };
        this.locType = null;
      } else if (vsetCode === 'to_fa_location') {
        this.toLocation = {
          text: null,
          value: null
        };
        this.locType = null;
      } else if (vsetCode === 'status') {
        this.status = {
          value: null,
          text: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
